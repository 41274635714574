body {
  font-family: 'Lato', 'Noto Color Emoji', sans-serif;
}

div[disabled] {
  pointer-events: none;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.pz-avatar {
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.ant-btn {
  border-radius: 4px;
}

.action-btn {
  height: 37px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0 22px;
  color: #00A7CF;
  box-shadow: 0px 1px 0px #e5e5e5;

  &:disabled {
    color: rgba(#00A7CF, .6) !important;
  }

  &-primary {
    color: #fff;
    background: #00A7CF;
    border-color: #00A7CF;
    box-shadow: 0px 1px 0px #0794B6;

    &:hover, &:focus, &:visited {
      color: #fff;
      background: #00A7CF;
      border-color: #00A7CF;
      border-color: rgba(#000, .2);
    }

    &:disabled {
      background: #00A7CF !important;
      border-color: #00A7CF !important;
      color: rgba(#fff, .6) !important;
      box-shadow: 0px 1px 0px rgb(115, 188, 206) !important;
    }
  }
}

.responsive-svg-btn {
  svg {
    height: 100%;
    display: none;
  }

  @media (max-width: 1180px) {
    padding: 0 15px !important;

    svg {
      display: inline;
    }

    span {
      display: none;
    }
  }

  @media (max-width: 650px) {
    display: none !important;
  }
}

.ant-pagination-item-active {
  border-color: #79ACCD;
}

.ant-btn-primary {
  background-color: #00A7CF;
  border-color: #00A7CF;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #79ACCD;
}


.ant-modal-content {
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
    text-transform: uppercase;
    border: 0;
    
    .ant-modal-title {
      font-weight: bold;
      color: #636466;
    }
  }

  .ant-modal-body {
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-modal-footer {
    border: 0;
  }
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-table-placeholder {
  background: transparent;
}
